<template>
  <div class="all">
    <!-- 搜索栏 -->
    <div class="header">
      <div class="header_left">
         <img src="http://img.taojiefang.cn/FizvzrUrC6Gwew9nAmgyHAHHGg_N" alt class="t_logo_img">
      </div>
			<input type="text" v-model="keyword" class="header_input" placeholder="输入宝贝链接，搜索隐藏优惠券" @keyup.enter="_gotoSearch">
      <div class="header_right" @click="get_classify()">
        <img src="@/assets/common/classify.png" alt class="classify_icon">
      </div>
    </div>

    <!-- banner图 -->
    <div class="banner_box">
      <banner-bar :bannerType="1"></banner-bar>
    </div>

    <!-- 猜你喜欢 -->
    <div class="guess_list">
      <div class="guess_title">
        <div class="guess_icon"></div>
        <span>今日推荐</span>
      </div>
      <van-list v-model="loading" :finished="finished" :offset="200" @load="initguess">
        <ul class="goods-top-list">
          <li v-for="(item, index) in guess_list" :key="index" @click="gotoGoodsItem(item)">
            <img :src="item.pict_url" alt class="clothes_img" v-lazy="item.pict_url">
            <div class="guess_box">
              <span class="price">¥{{item.price}}</span>
              <span class="sales">月销量{{item.volume}}件</span>
            </div>
            <div class="guess_content">{{item.goods_title}}</div>
          </li>
        </ul>
      </van-list>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import BannerBar from "./components/BannerBar";
import { Row, Col, Popup, List, Lazyload } from "vant";
import Customer from "@/components/customer/Index";
import ls from "@/libs/localstore";
import { guessList } from "@/api/newshop";
import { getSelfProducts } from '@/api/self-product'

Vue.use(Row)
  .use(Col)
  .use(Popup)
  .use(List)
  .use(Lazyload);

export default {
	name: "Shop",
  components: {
    BannerBar,
		Customer
  },
  data() {
    return {
      toPage: 1,
      rows: 2,
      loading: false,
			finished: false,
			loading2: false,
      finished2: false,
      history_msg: [],
      shareImage: "http://img.quanminhuyu.com/FvK1DacWYRJe98skI7ZQwxBJGIaQ",
      activity_list: [
        // { activity_title: "优惠充话费", uesr: "新人专享" },
        // { activity_title: "优惠充话费", uesr: "新人专享" },
        // { activity_title: "优惠充话费", uesr: "新人专享" }
      ],
      search_list: [],
      guess_list: [],
      special_list: [],
      show: false,
      search: "",
      new_search: [],
			no_login: [],
			self_products: [],
			keyword: ''
    };
  },
  computed: {},
  mounted() {
    this._initShare();
  },

  methods: {
    _initShare() {
      this._shareTimeline();
      this._shareAppMessage();
      this._updateTimelineShareData();
      this._updateAppMessageShareData();
		},
		_gotoSearch() {
			this.$router.push({ path: "/GoodsRecommend", query: {keyword: this.keyword}})
		},
		// 自营商品
		_getSlefProducts() {
			this.loading2 = true
			getSelfProducts().then(res => {
				this.loading2 = false
				this.finished2 = true
				if(res.errCode === 0) {
					this.self_products = res.data
				}
			}).catch(err => {
				this.loading2 = false
				this.finished2 = true
			})
		},
    //猜你喜欢
    initguess() {
      let params = {
        toPage: this.toPage,
        rows: 20
      };
      guessList(params).then(res => {
        if (res.errCode == 0) {
          if (res.data.length > 0) {
            this.guess_list = this.guess_list.concat(res.data);
            this.toPage += 1;
          } else {
            this.finished = true;
          }
        } else {
          this.finished = true;
        }
        this.loading = false;
      });
    },
    //跳转商品详情页
    gotoGoodsItem(item) {
      this.$router.push({ path: "/goodsDetail", query: { id: item.goods_id } });
    },
    // 右上角菜单分享朋友圈
    _shareTimeline() {
      let that = this;
      this.$wechat.onMenuShareTimeline({
        title: "【您有一个10元红包待领取】邀请好友还可以返现金，我已提现！", // 分享标题
        link: "http ://www.taojiefang.cn/goto.html?goto=shop_list&params=",
        imgUrl: that.shareImage, // 分享图标
        success: function() {}
      });
    },
    // 右上角菜单分享给朋友
    _shareAppMessage() {
      let that = this;
      this.$wechat.onMenuShareAppMessage({
        title: "【您有一个10元红包待领取】邀请好友还可以返现金，我已提现！", // 分享标题
        desc: "淘街坊提供的疯狂抵现活动福利，邀请好友帮砍价即可1元购买", // 分享描述
        link: "http ://www.taojiefang.cn/goto.html?goto=shop_list&params=",
        imgUrl: that.shareImage, // 分享图标
        success: function() {}
      });
    },
    // 分享朋友圈
    _updateTimelineShareData() {
      let that = this;
      this.$wechat.ready(function() {
        that.$wechat.updateTimelineShareData({
          title: "【您有一个10元红包待领取】邀请好友还可以返现金，我已提现！", // 分享标题
          link: "http ://www.taojiefang.cn/goto.html?goto=shop_list&params=",
          imgUrl: that.shareImage, // 分享图标
          success: function() {}
        });
      });
    },
    // 分享朋友
    _updateAppMessageShareData() {
      let that = this;
      this.$wechat.ready(function() {
        that.$wechat.updateAppMessageShareData({
          title: "【您有一个10元红包待领取】邀请好友还可以返现金，我已提现！", // 分享标题
          desc: "淘街坊提供的疯狂抵现活动福利，邀请好友帮砍价即可1元购买", // 分享描述
          link: "http ://www.taojiefang.cn/goto.html?goto=shop_list&params=",
          imgUrl: that.shareImage, // 分享图标
          success: function() {}
        });
      });
    },
    get_classify() {
      this.$router.push({ path: "/classify" });
    }
  }
};
</script>

<style scoped>
.all {
  background: #fcfcfc;
}
.goods-list,
.goods-top-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 15px 0 14px;
}

.goods-list li,
.goods-top-list li {
  width: 168px;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(218, 218, 218, 0.3);
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
}

.goods-list li img,
.goods-top-list li img {
  width: 168px;
  height: 154px;
}

.goods-list .goods-right img {
  height: 200px;
}

.header {
  padding: 7px 21px 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .header_left {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  color: #4d4d4d;
}
.header_left .service_icon {
  width: 26px;
  height: 26px;
}
.header .header_input {
  height: 32px;
  flex: 1;
  margin: 0 10px 0 8px;
  background: #f1f1f1;
  border-radius: 100px;
  outline: none;
  border: none;
  font-size: 14px;
  color: #666;
  padding: 0 23px;
  display: flex;
  align-items: center;
}
input::-webkit-input-placeholder {
  font-size: 14px;
  color: #bcbcbc;
  padding-left: 23px;
}
.header .header_right {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  color: #666;
}
.header_right .classify_icon {
  width: 22px;
  height: 22px;
}
.banner_box {
  width: 350px;
  margin: 0 12px;
}

.tab_list {
  margin: 25px 26px 35px 26px;
}
.items {
  display: flex;
}
.items_two{
display: flex;
margin-top: 12px;
}
.tab_items {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
}
.tab_items .tab_title {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
}
 .tab_items .icon_img {
  width: 44px;
  height: 44px;
}
.special_box {
  /* margin: 0 22px 37px 21px; */
  margin: 0 12.5px 30px 12.5px;
}
.special_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #4d4d4d;
  margin: 0 27px 20px;
}
.special_title .tree_icon {
  width: 6px;
  height: 19px;
  background-image: linear-gradient(231deg, #ffc3f3 0%, #ff9797 100%);
  border-radius: 3px;
  margin: 0 7px 0 0;
}
.special_img {
  width: 350px;
  height: 150px;
  margin-bottom: 2px;
  background: #e4e4e4;
  border-radius: 4px;
  box-shadow: 2px 3px 8px #dadada;
}
.special_photo {
  display: flex;
  flex-wrap: wrap;
}
.special_photo .special_photo_one {
  width: 49.5%;
  height: 49.5%;
  background: #e4e4e4;
  border-radius: 4px;
  margin-right: 1px;
  margin-top: 5px;
}
.special_photo div:last-child .special_photo_one {
  margin-right: 0;
}
.activity_box {
  margin-left: 12px;
}
.activity_box .activity_title {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #4d4d4d;
  margin-bottom: 16px;
  margin-left: 15px;
  justify-content: space-between;
}
.activity_title .activity_icon {
  width: 6px;
  height: 19px;
  background-image: linear-gradient(231deg, #ffe9ae 0%, #ffbe92 100%);
  border-radius: 3px;
  margin-right: 7px;
}
.ac_left {
  display: flex;
}
.ac_img {
  width: 63px;
  height: 63px;
  /* background: #adadad; */
  border-radius: 100px;
  margin-right: 36px;
}
.activity_items {
  margin: 0 13px 0 0;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(218, 218, 218, 0.1);
  border-radius: 4px;
}
.activity_items .ac_list {
  padding: 11px 13px 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.activity_items li:last-child {
  margin-bottom: 30px;
}
.ac_content {
  margin-top: 8px;
}
.ac_content .activity_t {
  font-size: 18px;
  color: #666666;
}
.ac_content .uesr {
  font-size: 16px;
  color: #ababab;
}
.right_icon {
  height: 26px;
}
.guess_list .guess_icon {
  width: 6px;
  height: 19px;
  background-image: linear-gradient(231deg, #d6f0ff 0%, #8caeff 100%);
  border-radius: 3px;
  margin-right: 9px;
}
.guess_list .guess_title {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #4d4d4d;
  margin: 20px 20px;
}
.guess_items {
  margin: 0 26px 0 25px;
  display: flex;
  flex-wrap: wrap;
}
.guess_del {
  margin-right: 16px;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(218, 218, 218, 0.3);
  border-radius: 4px;
  /* width: 50%; */
  width: 150px;
  margin-bottom: 10px;
  overflow: hidden;
}

.guess_del .clothes_img {
  /* width: 154px; */
  height: 154px;
  background: red;
}
.guess_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 0 7px;
}
.guess_box .price {
  font-size: 12px;
  color: #f23182;
}

.guess_box .sales {
  font-size: 12px;
  color: #9b9b9b;
}
.guess_content {
  font-size: 12px;
  color: #666666;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px 8px 8px 7px;
}
/* .top_popup >>> .van-popup{
  position: absolute;
} */
.top_popup >>> .van-overlay {
  background: rgba(0, 0, 0, 0.5);
}
.top_popup >>> .van-popup--top {
  border-radius: 0 0 14px 14px;
}
.search_content {
  width: 100%;
  background: #ffffff;
}
.search_header {
  padding: 8px 16px 0 22px;
}
.search_header .search_input {
  width: 270px;
  height: 36px;
  background: #f1f1f1;
  border-radius: 100px;
  color: #4d4d4d;
  padding: 0 17px;
  font-size: 14px;
}
.search_header input::-webkit-input-placeholder {
  font-size: 14px;
  color: #4d4d4d;
  padding-left: 17px;
}
.search_header .search_btn {
  font-size: 14px;
  color: #fff;
  background-image: linear-gradient(140deg, #ffccfd 0%, #ff9c9c 100%);
  border-radius: 11.5px;
  padding: 2px 6px 2px 7px;
  margin-left: 8px;
}
.search_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 22px 24.5px 14px 31px;
  font-size: 14px;
  color: #4d4d4d;
}
.delete_img {
  width: 16px;
  height: 16px;
}
.history_box {
  margin: 0 36px 14px 27px;
  font-size: 14px;
  color: #4d4d4d;
  display: flex;
  flex-wrap: wrap;
  /* height: 155px; */

  /* align-items: center; */
}
.history_box .history_list {
  padding: 4px 14px;
  background: #f7f7f7;
  border-radius: 14px;
  /* width: 33.3333%; */
  /* overflow: hidden; */
  /* white-space: nowrap;
  text-overflow: ellipsis; */
  margin: 0 14px 14px 0;
  text-align: center;
  box-sizing: border-box;
}
.more_btn {
  font-size: 14px;
  color: #fff;
  border-radius: 100px;
  padding: 2px 6px 2px 7px;
  background-image: linear-gradient(-231deg, #ffcccc 0%, #ffa0a0 100%);
  margin-right: 8px;
}
.activity_more_btn {
  font-size: 14px;
  color: #fff;
  border-radius: 100px;
  padding: 2px 6px 2px 7px;
  background-image: linear-gradient(-231deg, #ffcccc 0%, #ffa0a0 100%);
  margin-right: 21px;
}
.special {
  display: flex;
  align-items: center;
}
.t_logo_img {
  width: 30px;
	margin-left: -15px;
}
</style>
