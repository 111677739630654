// 自营列表
export const getSelfProducts 				= (params) => window._http('/SelfProduct-index', params)

// 自营商品详情
export const getSelfProductItem 				= (params) => window._http('/SelfProduct-item', params)

// 获取商品详情
export const getDetailContent 				= (params) => window._http('/SelfProduct-detailContent', params)

// 商品结算
export const getSettlementList 				= (params) => window._http('/SelfProduct-getSettlementList', params)

// 商品推广码
export const getShareInfo 				= (params) => window._http('/SelfProduct-getShareInfo', params)