<template>
	<div>
		<div class="customer_bar" @click="_showCustomer">
       <img src="@/assets/common/service.png" alt >
    </div>
		<show ref="ShowQrcode"></show>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { getCustomerInfo } from '@/api/customer'
import { Popup } from 'vant'
import Show from './Show'
import ls from '@/libs/localstore'

Vue.use(Popup)

export default {
  components: {
    Show
  },
  data () {
    return {
    }
  },
  computed: {},
  created () {},
  mounted () {
  },
  methods: {
    _showCustomer () {
      this.$refs.ShowQrcode.show()
    }
  }
}
</script>

<style scoped>
.customer_bar {
	width: 42px;
  height: 42px;
  position: fixed;
  bottom: 110px;
  right: 20px;
	/* z-index: 2; */
  border-radius: 100px;
  background: #fff;
  text-align: center;
display: flex;
align-items: center;
justify-content: center;
z-index: 999;
}

.customer_bar img {
   width: 30px;
  /* height: 26px; */
}
</style>
