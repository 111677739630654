// 猜你喜欢
export const guessList 					= (params) => window._http('/Product-mayLike', params)

// 获取专题
export const special_list 			= (params) => window._http('/Topic-index', params)

// 热门活动
export const activity_list 			= (params) => window._http('/Activity-getHot', params)

// 添加搜索
export const Search_add 				= (params) => window._http('/Search-add', params)

// 获取历史记录
export const Search_getHistory 	= (params) => window._http('/Search-getHistory', params)

// 删除历史记录
export const del_destroy 				= (params) => window._http('/Search-destroy', params)