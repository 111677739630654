<template>
  <div class="page_container">
    <div ref="BannerBar" width="100%">
      <van-swipe
        v-if="banners.length>0 && flag"
        class="banner"
        :autoplay="3000"
        ref="Banner"
        indicator-color="#fff"
      >
        <van-swipe-item v-for="(banner, index) in banners" :key="index">
          <a :href="banner.weblink">
            <img :style="{width: '100%'}" :src="banner.url">
          </a>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem, Lazyload } from "vant";
import { getBanner } from "@/api/shop";

Vue.use(Swipe)
  .use(SwipeItem)
  .use(Lazyload);
export default {
	name: "BannerBar",
	props: {
		bannerType: {
			type: Number,
			default: 0
		},
	},
  data() {
    return {
      flag: true,
      banners: [],
      bannerHeight: 0,
      banner_current: 0
    };
  },
  activated() {
    this.flag = true;
  },
  deactivated() {
    this.flag = false;
  },
  mounted() {
    // this._initBannerHeight();
    this._loadData();
  },
  methods: {
    _initBannerHeight() {
      let clientWidth = this.$refs.BannerBar.clientWidth;
      this.bannerHeight = (clientWidth * 292) / 1125 + "px";
    },
    _loadData() {
      // 请求参数
      let params = {
        type: this.bannerType
      };
      // 请求分类列表
      let that = this;
      getBanner(params)
        .then(res => {
          if (res.errCode == 0) {
            this.banners = res.data;
          }
				})
				.catch(err => {
					console.error(err.errMsg)
				})
    },
    _bannerChange(index) {
      this.banner_current = index;
    }
  }
};
</script>

<style scoped>
.page_container {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  font-size: 0;
  box-shadow: 2px 3px 8px #dadada;
}
</style>
